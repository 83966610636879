@tailwind base;
@tailwind components;
@tailwind utilities;
//@import "tailwind.css";


/* -------------------------------------------------------------------------- */
/*                                    BASE                                    */
/* -------------------------------------------------------------------------- */

@layer base {

  /* --------------------------- default color theme -------------------------- */

  :root {
    --color-accent: #3b82f6;//theme('colors.indigo.600');
    --color-text-base: theme('colors.gray.900');
  }

  body {
    font-family: theme('fontFamily.sans');
  }


  /* -------------------------- default form settings ------------------------- */

  label {
    @apply block text-sm font-medium;
  }

  input + label {
    display:inline;
    font-weight:normal;
  }

  [type='text'],
  [type='email'],
  [type='url'],
  [type='password'],
  [type='number'],
  [type='date'],
  [type='datetime-local'],
  [type='month'],
  [type='search'],
  [type='tel'],
  [type='time'],
  [type='week'],
  [multiple],
  textarea,
  select {
    @apply focus:ring-skin-accent focus:border-skin-accent sm:text-sm block w-full mt-1 border-gray-300 rounded-md shadow-sm;
  }

  [type='checkbox'],
  [type='radio'] {
    @apply focus:ring-0 text-skin-accent w-4 h-4 border-gray-300;
  }

  [type='checkbox'] {
    @apply rounded;
  }

}

.vc-select select {
  background-image: none;
}

